import { createTheme } from "@mui/material/styles";


const BreakPointTheme = createTheme({
  breakpoints: {
    values: {
      xs: 319,
      sm: 499,
      md: 719,
      bg: 899,
      lg: 1199
    }
  }
});

export { BreakPointTheme };
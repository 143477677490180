import React, { useState } from "react";
import logo from "./logo.svg";
import "normalize.css";
import "./App.css";

import LocationButton from "./components/LocationButton/LocationButton";
import Template from "./components/Template/Template";

const App = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleTemplate = () => {
    console.log("clicked");
    setIsOpen(!isOpen);

    document.body.classList.toggle("modal-open");
  };

  return (
    <div className="region-search">
      <LocationButton onClick={toggleTemplate}></LocationButton>
      {isOpen && <Template onCloseClick={toggleTemplate}></Template>}
    </div>
  );
};

export default App;
